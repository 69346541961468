<template>
  <div class="bg-white min-h-screen">
    <!-- Notification Bar -->
    <div class="bg-[#f3e9ea] py-2">
      <div class="container mx-auto xl:px-4 lg:px-12 md:px-16 sm:px-5 px-4">
        <div class="grid grid-cols-1 gap-4 text-center">
          <div class="flex items-center justify-center hover:text-black transition-colors duration-300">
            <p class="md:text-lg flex sm:items-center lg:text-lg sm:text-base text-[12px] nunito-sans-light">
              <span><img src="@/assets/Notification.svg" alt="Notification Icon" class="sm:w-3.5 w-3 sm:mt-0 mt-0.5 sm:mr-2 mr-1"></span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Breadcrumb Navigation -->
    <div class="container mx-auto px-5 nunito-sans-light">
      <div class="text-sm text-[#7A7A7A] my-8">
        <nav aria-label="Breadcrumb">
          <ol class="list-reset flex">
            <li><router-link to="/" class="text-[#B8B8B8]">Home</router-link><span class="mx-2 text-[#B8B8B8]">></span></li>
            <li><router-link to="/past-auction" class="text-[#B8B8B8]">Past Auction</router-link><span class="mx-2 text-[#B8B8B8]">></span></li>
            <li><span class="text-[#3D3D3D]">{{ product.title }}</span></li>
          </ol>
        </nav>
      </div>

      <!-- Product Details Section -->
      <div class="flex flex-col md:flex-row gap-6">
        <!-- Gallery Section -->
        <div class="w-full md:w-1/2">
          <img :src="product.image" alt="Product Image" class="w-full object-cover border border-grey" />
          <div class="flex gap-2 mt-4">
            <img 
              v-for="(img, index) in product.gallery" 
              :key="index" 
              :src="img" 
              alt="Gallery Image" 
              class="w-16 h-16 object-cover border border-gray-300" 
     :style="{ border: selectedImageIndex === index ? '1px solid black !important' : '' }"
              @click="selectImage(index)"
            />
            <div v-if="product.gallery.length > 3" class="flex items-center justify-center w-16 h-16 border border-gray-300 text-gray-500">+{{ product.gallery.length - 3 }}</div>
          </div>
        </div>

        <!-- Product Info Section -->
        <div class="w-full md:w-1/3 space-y-4">
          <div class="flex justify-between items-center">
            <p class="text-[#7A7A7A] nunito-sans-light"><span>Lot #:</span> {{ product.lotNumber }}</p>
            <p class="text-[#7A7A7A] nunito-sans-light text-right"><span>Sold Date:</span> {{ product.soldDate }}</p>
          </div>
          
          <h1 class="text-2xl text-gray-800 raleway-custom">{{ product.title }}</h1>
           
          <div class="flex justify-between space-x-4 ">
            <div class="flex flex-col border border-gray-300 py-3 px-8 w-40 text-center ">
              <span class="text-[#7A7A7A] text-[14px] nunito-sans-light">Reserve Price:</span>
              <span class="text-gray-800 font-semibold nunito-sans-light text-[20px]">${{ product.reservePrice }}</span>
            </div>
            <div class="flex flex-col py-3 px-8 w-40 text-center bg-[#f3e9ea]">
              <span class="text-[#7A7A7A] text-[14px] nunito-sans-light">Winning Bid:</span>
              <span class="text-[#8a2432] font-semibold text-[20px] nunito-sans-light">${{ product.winningBid }}</span>
            </div>
          </div>
          
          <div class="space-y-1 pt-2 nunito-sans-light">
            <div class="flex justify-between">
              <p class="text-[#7A7A7A]">Distillery/Brand:</p>
              <div class="ml-auto text-left w-40">
                <p class="text-black">{{ product.distillery }}</p>
              </div>
            </div>
            <div class="flex justify-between">
              <p class="text-[#7A7A7A]">Region:</p>
              <div class="ml-auto text-left w-40">
                <p class="text-black">{{ product.region }}</p>
              </div>
            </div>
            <div class="flex justify-between">
              <p class="text-[#7A7A7A]">Age:</p>
              <div class="ml-auto text-left w-40">
                <p class="text-black">{{ product.age }}</p>
              </div>
            </div>
            <div class="flex justify-between">
              <p class="text-[#7A7A7A]">Whisky Type:</p>
              <div class="ml-auto text-left w-40">
                <p class="text-black">{{ product.whiskyType }}</p>
              </div>
            </div>
            <div class="flex justify-between">
              <p class="text-[#7A7A7A]">Bottles Produced:</p>
              <div class="ml-auto text-left w-40">
                <p class="text-black">{{ product.bottlesProduced }}</p>
              </div>
            </div>
            <div class="flex justify-between">
              <p class="text-[#7A7A7A]">Bottle Number:</p>
              <div class="ml-auto text-left w-40">
                <p class="text-black">{{ product.bottleNumber }}</p>
              </div>
            </div>
            <div class="flex justify-between">
              <p class="text-[#7A7A7A]">Size:</p>
              <div class="ml-auto text-left w-40">
                <p class="text-black">{{ product.size }}</p>
              </div>
            </div>
            <div class="flex justify-between">
              <p class="text-[#7A7A7A] ">Strength:</p>
              <div class="ml-auto text-left w-40">
                <p class="text-black">{{ product.strength }}</p>
              </div>
            </div>
          </div>
          <button data-v-bc208bea="" class="bg-[#8a2432] border border-[#8a2432] text-white xl:py-4 lg:py-4 md:py-4 sm:py-3 py-1.5 xl:px-8 lg:px-8 md:px-8 sm:px-4 px-4 hover:border-white transition hover:bg-white hover:text-[#8a2432] nunito-sans-light">Add To Cart</button>
        </div>
      </div>

      <!-- Tabs for Description and About Distillery -->
      <div class="mt-12">
        <div class="flex space-x-4  border-gray-300">
          <button @click="activeTab = 'description'" :class="{ 'border-b-2 border-gray-800 text-gray-800 font-semibold': activeTab === 'description' }" class="pb-2">Description</button>
          <button @click="activeTab = 'aboutDistillery'" :class="{ 'border-b-2 border-gray-800 text-gray-800 font-semibold': activeTab === 'aboutDistillery' }" class="pb-2">About Distillery</button>
        </div>
        
        <div v-if="activeTab === 'description'" class="mt-4 text-[#7A7A7A]">
          <p>{{ product.description }}</p>
        </div>
        
        <div v-if="activeTab === 'aboutDistillery'" class="mt-4 text-[#7A7A7A]">
          <p>{{ product.aboutDistillery }}</p>
        </div>
      </div>
      
      <!-- Note Section -->
      <div class="bg-[#f3e9ea] mt-10 p-8 text-[#8a2432] text-[14px]">
        <p><strong>Note: </strong>Please closely examine the images provided in this lot prior to placing a bid. High resolution images are provided when viewing from a computer. All lots being sold are second hand in nature and all condition defects are not specifically listed in the lot description. Lots will be sold as seen in the images provided on each lot.</p>
      </div>
              <div class="mt-10">
        <h1 class="text-[24px] raleway-custom leading-tight  uppercase" data-v-b5c8abc4=""> Related Auctions</h1>
        <ProductListSlider/>
        </div>
    </div>

    <div class="pt-10">
      <SiteFooter/>
    </div>
  </div>
</template>

<script>
import SiteFooter from "@/components/SiteFooter.vue";
import ProductListSlider from '@/components/ProductListSlider.vue';

export default {
  components: {
    SiteFooter,
      ProductListSlider, 
  },
  data() {
    return {
      activeTab: 'description', // Track the active tab
      selectedImageIndex: 0, // Track the selected image index
      product: {
        id: 1,
        soldDate: "21/09/2024",
        image: require('@/assets/1718862141IMG-0347 4.png'),
        gallery: [
          require('@/assets/1718862141IMG-0347 4.png'),
          require('@/assets/1718862141IMG-0347 4.png'),
          require('@/assets/1718862141IMG-0347 4.png')
        ],
        lotNumber: "357830",
        reservePrice: "13000.00",
        winningBid: "10993.00",
        title: "Woodford Reserve Very Fine Rare American Bourbon Whiskey",
        distillery: "Woodford Reserve",
        region: "America",
        age: "12 years",
        whiskyType: "Bourbon",
        bottlesProduced: "132",
        bottleNumber: "56",
        size: "700ml",
        strength: "45%",
        description: "A fine American Bourbon with a rich flavor profile Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat",
        aboutDistillery: "Woodford Reserve is a renowned distillery known for high-quality American bourbon Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat"
      }
    };
  },
  methods: {
    selectImage(index) {
      this.selectedImageIndex = index;
    },
  },
};
</script>

<style scoped>
/* You can customize the [#7A7A7A] styling for the selected image here */
.gallery-images img {
  object-fit: cover;
  width: 100%;
  max-width: 100px;
  height: auto;
}
</style>

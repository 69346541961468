<template>
  <div class="bg-white min-h-screen">
    <!-- Notification Bar -->
    <div class="bg-[#f3e9ea] py-2">
      <div class="container mx-auto xl:px-4 lg:px-12 md:px-16 sm:px-5 px-5">
        <div class="grid grid-cols-1 gap-4 text-center">
          <div class="flex items-center justify-center hover:text-black transition-colors duration-300">
            <p class="md:text-lg flex sm:items-center lg:text-lg sm:text-base text-[12px] nunito-sans-light">
              <span>
                <img src="@/assets/Notification.svg" alt="Notification Icon"
                  class="sm:w-3.5 w-3 sm:mt-0 mt-0.5 sm:mr-2 mr-1" />
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Registration Form -->
    <div class="container mx-auto px-5 py-16">
      <h2 class="text-[24px] raleway-custom uppercase mb-6">Register Your Account</h2>
      <form class="grid grid-cols-2 gap-4">
        <!-- Left Column (Input Fields) -->
        <div class="col-span-2 md:col-span-1 pe-5">
          <label for="first-name" class="block nunito-sans-light pb-1">First Name</label>
          <input type="text" id="first-name" class="w-full h-12 border border-black px-3 py-2 mb-4" />

          <label for="email" class="block nunito-sans-light pb-1">Email</label>
          <input type="email" id="email" class="w-full h-12 border border-black px-3 py-2 mb-4" />

          <label for="password" class="block nunito-sans-light pb-1">Password</label>
          <div class="relative">
            <input :type="showPassword ? 'text' : 'password'" id="password" class="w-full h-12 border border-black px-3 py-2 mb-4 pr-10" />
            <img src="@/assets/eye-open.svg" alt="Toggle Password Visibility"
                 @click="togglePasswordVisibility"
                 class="absolute top-1/3 right-3 transform -translate-y-1/2 cursor-pointer" />
          </div>

          <label for="dob" class="block nunito-sans-light pb-1">Date of Birth</label>
          <input type="date" id="dob" class="w-full h-12 border border-black  text-[#B8B8B8] px-3 py-2 mb-4" />
        </div>

        <!-- Right Column (Confirm Password & Checkboxes) -->
        <div class="col-span-2 md:col-span-1 ps-5">
          <label for="last-name" class="block nunito-sans-light pb-1">Last Name</label>
          <input type="text" id="last-name" class="w-full h-12 border border-black px-3 py-2 mb-4" />

          <label for="contact-number" class="block nunito-sans-light pb-1">Contact Number</label>
          <input type="text" id="contact-number" class="w-full h-12 border border-black px-3 py-2 mb-4" />

          <label for="confirm-password" class="block nunito-sans-light pb-1">Confirm Password</label>
          <div class="relative">
            <input :type="showConfirmPassword ? 'text' : 'password'" id="confirm-password" class="w-full h-12 border border-black px-3 py-2 mb-4 pr-10" />
            <img src="@/assets/eye-open.svg" alt="Toggle Confirm Password Visibility"
                 @click="toggleConfirmPasswordVisibility"
                 class="absolute top-1/3 right-3 transform -translate-y-1/2 cursor-pointer" />
          </div>

          <!-- Checkbox Section -->
          <div class="mt-7">
            <div class="flex items-center gap-2">
              <input type="checkbox" id="sell" class="custom-checkbox" />
              <label for="sell" class="text-[12px] leading-3">I want to sell</label>
            </div>
            <div class="flex items-center gap-2 mt-2">
              <input type="checkbox" id="newsletter" class="custom-checkbox" />
              <label for="newsletter" class="text-[12px] leading-3">Subscribe to our Newsletter</label>
            </div>
            <div class="flex items-center gap-2 mt-2">
              <input type="checkbox" id="terms" class="custom-checkbox" required />
              <label for="terms" class="text-[12px] leading-3">
                Please confirm that you have read & agree to our
                <a href="#" class="text-[#8a2432]">Terms & Conditions</a>
              </label>
            </div>
            <p class="text-black text-[12px] mt-8">
              One-time Registration Fee of $10 will be applicable.
            </p>
            <button type="submit" class="w-full h-12 bg-[#8a2432] text-white py-2 mt-2">
              Register
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-- Footer -->
    <SiteFooter />
  </div>
</template>

<script>
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  components: {
    SiteFooter,
  },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1280px;
}

/* Custom Checkbox Style */
.custom-checkbox {
  appearance: none;
  width: 12px;
  height: 12px;
  border: 1px solid #8A2432;
  background-color: white;
  cursor: pointer;
  outline: none;
  position: relative;
}

.custom-checkbox:checked {
  background-color: #8A2432;
}

.custom-checkbox:checked::after {
content: '';
  position: absolute;
  left: 4px;
  top: -1px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}





</style>

import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';  // Import for HomePage
import LiveAuctionPage from '../views/LiveAuctionPage.vue'; // Import for LiveAuctionPage
import PastAuction from '@/views/PastAuction.vue';  // Import for PastAuction component
import PastAuctionlot from '@/views/PastAuctionlot.vue'; // Import for PastAuctionlot component
import PastAuctionDetailPage from'@/views/PastAuctionDetailPage.vue'; // Import for PastAuctionDetailPage component
import RegisterUser from'@/views/RegisterUser.vue'; // Import for RegisterUser component
import LoginUser from'@/views/LoginUser.vue'; // Import for LoginUser component
import LiveAuction from'@/views/LiveAuction.vue'; // Import for LiveAuction component
import RequestAndValuation from'@/views/RequestAndValuation.vue'; // Import for RequestAndValuation component





// Define the routes
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/live-auctioffffffn', // Path for Live Auction
    name: 'live-auctioffffffn',
    component: LiveAuctionPage,
  },
  {
    path: '/request-Valuation', // Path for Live Auction
    name: 'RequestAndValuation',
    component: RequestAndValuation,
  },
  {
    path: '/login', // Path for Live Auction
    name: 'LoginUser',
    component: LoginUser,
  },
  {
    path: '/register-user', // Path for Live Auction
    name: 'RegisterUser',
    component: RegisterUser,
  },
  {
    path: '/past-auction-lot/:slug', // Dynamic route with slug parameter
    name: 'PastAuctionlot',
    component: PastAuctionlot,
    props: true, // Pass slug as a prop to PastAuctionlot component
  },
  {
    path: '/past-auction', // Static route for PastAuction
    name: 'PastAuction',
    component: PastAuction,
    props: true,  // Pass params to the component (useful for any future dynamic routing)
  },
  {
    path: '/live-auction', // Static route for PastAuction
    name: 'LiveAuction',
    component: LiveAuction,
    props: true,  // Pass params to the component (useful for any future dynamic routing)
  },

  {
    path: '/pastAuctionDetailPage/:id', // Dynamic route with 'id' parameter
    name: 'PastAuctionDetailPage',
    component: PastAuctionDetailPage,
    props: true,  // Pass route params as props to the component
  },

  
];

// Create and export the router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

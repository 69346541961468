<template>
  <div class="bg-white min-h-screen">
    <!-- Auction Notification Banner -->
    <section class="bg-[#f3e9ea] py-2">
      <div class="container mx-auto xl:px-4 lg:px-12 md:px-16 sm:px-10 px-6">
        <div class="flex justify-center hover:text-black transition-colors duration-300 text-center">
          <p class="md:text-lg sm:text-base text-[12px] nunito-sans-light flex items-center">
            <img src="@/assets/Notification.svg" alt="Notification Icon" class="sm:w-3.5 w-3 sm:mr-2 mr-1" />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
      </div>
    </section>

    <!-- Breadcrumb Navigation -->
    <nav aria-label="breadcrumb" class="container mx-auto xl:px-4 lg:px-12 md:px-16 sm:px-10 px-6 py-8 text-sm text-gray-600 nunito-sans-light">
      <ol class="list-reset flex">
        <li>
          <a href="#" class="text-[#B8B8B8]">Home</a>
          <span class="mx-2 text-[#B8B8B8]">></span>
        </li>
        <li>
          <a href="#" class="text-[#3D3D3D]">Past Auction</a>
        </li>
      </ol>
    </nav>

    <!-- Header / Banner Component -->
    <LiveAuctionBanner />

    <!-- Main Content -->
    <main class="container mx-auto xl:px-4 lg:px-12 md:px-16 sm:px-10 px-6 py-16">
      <!-- Filter Bar -->
      <div class="filter-bar flex space-x-8 text-lg  nunito-sans-light mb-4">
        <button
          :class="['py-2 transition duration-300', selectedCategory === 'all' ? 'border-b-2 font-bold border-black' : '']"
          @click="setCategory('all')"
        >
          All
        </button>
        <button
          :class="['py-2 transition duration-300', selectedCategory === 'monthly' ? 'border-b-2 font-bold border-black' : '']"
          @click="setCategory('monthly')"
        >
          Monthly
        </button>
        <button
          :class="['py-2 transition duration-300', selectedCategory === 'special' ? 'border-b-2 font-bold border-black' : '']"
          @click="setCategory('special')"
        >
          Special
        </button>
      </div>

      <!-- Auction Grid -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        <AuctionItem
          v-for="(auction, index) in auctions"
          :key="index"
          :image="auction.image"
          :title="auction.title"
          @click="redirectToAuctionDetail(auction)"
        />
      </div>
    </main>

    <!-- Footer Component -->
    <SiteFooter />
  </div>
</template>

<script>
import axios from 'axios';
import jsCookie from 'js-cookie';
import LiveAuctionBanner from '@/components/LiveAuctionBanner.vue';
import AuctionItem from '@/components/AuctionItem.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  components: {
    LiveAuctionBanner,
    AuctionItem,
    SiteFooter,
  },
  data() {
    return {
      auctions: [],
      selectedCategory: 'all',
    };
  },
  methods: {
    async fetchAuctions() {
      try {
        const response = await axios.get(`https://awa.gprlive.com/api/live-auctions`);
        this.auctions = response.data.Auctions || [];
      } catch (error) {
        console.error('Error fetching auctions:', error);
      }
    },
    setCategory(category) {
      this.selectedCategory = category;
    },
    redirectToAuctionDetail(auction) {
      jsCookie.set('auction_id', auction.id, { expires: 7 });
      jsCookie.set('auction_title', auction.title, { expires: 7 });
      jsCookie.set('auction_description', auction.description, { expires: 7 });
      this.$router.push({ name: 'PastAuctionlot', params: { slug: auction.slug } });
    },
  },
  mounted() {
    this.fetchAuctions();
  },
};
</script>

<style>
.container {
  max-width: 1280px !important;
}
.filter-bar button {
  color: #3d3d3d;
}
.filter-bar button.border-b-2 {
  border-color: #3d3d3d;
}
</style>

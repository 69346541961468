<template>
  <div class="flex justify-center items-center min-h-screen relative">
    <!-- Background Image with Blur Effect -->
    <div class="absolute inset-0 bg-cover img_blur" :style="{ backgroundImage: `url(${require('@/assets/Login_page_BG_Blur.jpg')})` }"></div>

    <!-- Login Section -->
    <div class="bg-white flex shadow-lg absolute overflow-hidden max-w-5xl w-full z-10">
     
      <!-- Login Section -->
      <div class="w-1/2 p-12">
        <h2 class="text-[28px] mb-6">Login</h2>
        
        <!-- Email Input -->
        <input type="email" id="email" class="w-full border border-black px-3 py-2 mt-2 mb-4 nunito-sans-light text-[16px]" placeholder="Email" />
        
        <!-- Password Input -->
        <input type="password" id="password" class="w-full border border-black px-3 py-2 mt-2 mb-1 nunito-sans-light text-[16px]" placeholder="Password" />
        
        <!-- Forgot Password -->
        <div class="text-right text-[14px] nunito-sans-light text-[#7A7A7A] mb-6">
          <a href="#" class="hover:underline">Forgot Password?</a>
        </div>
        
        <!-- Login Button -->
        <button class="w-full text-white py-2 mb-3 hover:bg-withe bg-[#8a2432]">Login</button>
        
        <!-- Or Separator -->
        <div class="text-center text-gray-500 mb-3">Or</div>
        
        <!-- Login with Google Button -->
        <button class="w-full border border-black text-gray-700 py-2 flex items-center justify-center">
          Login with Google
          <img src="@/assets/icons8-google 1.svg" alt="Google Icon" class="w-5 h-5 ml-2">
        </button>
      </div>
      
      <!-- Right Section (Get Started) -->
      <div class="w-1/2 bg-[#F9F4F5] p-12">
        <h2 class="text-2xl text-[#8a2432] raleway-custom text-[28px] mb-6">New here? Get started</h2>
        
        <!-- Sell with Us -->
        <h3 class="text-[20px] text-black mb-2 raleway-custom">Sell with us</h3>
        <ul class="text-[#707070] text-[16px] nunito-sans-light list-disc pl-5 mb-4">
          <li>Lorem ipsum dolor</li>
          <li>Consectetur adipiscing elit</li>
          <li>Eiusmod tempor incididunt</li>
        </ul>
        
        <!-- Buy with Us -->
        <h3 class="text-[20px] text-black mb-2 raleway-custom">Buy with us</h3>
        <ul class="text-[#707070] text-[16px] nunito-sans-light list-disc pl-5 mb-6">
          <li>Lorem ipsum dolor</li>
          <li>Consectetur adipiscing elit eiusmod tempor</li>
          <li>Quis nostrud exercitation ullamco laboris nisi</li>
        </ul>
        
        <!-- Register Button with Click Event -->
        <button @click="redirectToRegister" class="w-full border border-[#8a2432] text-[#8a2432] py-2 hover:bg-[#8a2432] hover:text-white">Register Now</button>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <SiteFooter />
</template>

<script>
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  components: {
    SiteFooter,
  },
  methods: {
    redirectToRegister() {
      this.$router.push({ name: 'RegisterUser' });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1280px;
}

.bg-cover {
  background-size: cover;
}
</style>

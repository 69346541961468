import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Ensure this line imports your router
import './index.css'; // or your main CSS file
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import 'slick-carousel';

<script src="https://cdn.tailwindcss.com"></script>


const app = createApp(App);

app.use(router); // Ensure to use the router here

app.mount('#app');

<template>
  <div class="bg-white min-h-screen">
    <!-- Notification Bar -->
    <div class="bg-[#f3e9ea] py-2">
      <div class="container mx-auto xl:px-4 lg:px-12 md:px-16 sm:px-5 px-5">
        <div class="grid grid-cols-1 gap-4 text-center">
          <div class="flex items-center justify-center hover:text-black transition-colors duration-300">
            <p class="md:text-lg flex sm:items-center lg:text-lg sm:text-base text-[12px] nunito-sans-light">
              <span>
                <img src="@/assets/Notification.svg" alt="Notification Icon"
                  class="sm:w-3.5 w-3 sm:mt-0 mt-0.5 sm:mr-2 mr-1" />
              </span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Breadcrumb Navigation -->
    <div class="text-sm container mx-auto xl:px-4 lg:px-12 md:px-16 sm:px-8 px-6 py-8 text-gray-600">
      <nav aria-label="Breadcrumb nunito-sans-light">
        <ol class="list-reset flex">
          <li>
            <a href="#" class="text-[#B8B8B8]">Home</a>
            <span class="mx-2 text-[#B8B8B8]">></span>
          </li>
          <li>
            <a href="#" class="text-[#B8B8B8]">Past Auction</a>
            <span class="mx-2 text-[#B8B8B8]">></span>
          </li>
          <li>
            <span class="text-[#3D3D3D]">{{ topBannerData.title }}</span>
          </li>
        </ol>
      </nav>
    </div>

    <!-- Top Banner -->
    <div>
      <TopBanner 
        :title="topBannerData.title" 
        :description="topBannerData.description" 
        :longDescription="topBannerData.longDescription" 
      />
    </div>

    <!-- Main Content -->
    <div class="container mx-auto p-4">
      <div class="flex min-h-screen">
        <!-- Left Filter Bar -->
        <div class="w-1/4 pr-8 h-full">
          <FilterBar />
        </div>

        <!-- Right Content Area -->
        <div class="w-3/4">
          <div class="flex justify-between items-center mb-6 bg-[#f9f3f4] px-6 py-2">
            <div class="text-sm text-[#5C5C5C] nunito-sans-light">
              Total Lots: <span class="text-black">{{ products.length }}</span>
            </div>

            <div class="relative">
              <label class="mr-2 text-sm text-[#5C5C5C] nunito-sans-light">Sort By:</label>
              <div class="dropdown-container w-40" @click="toggleDropdown('sortOrder')">
                <div class="dropdown-select text-sm text-[#000] nunito-sans-light">
                  {{ sortOrderText }}
                </div>
                <div v-if="isSortOrderOpen" class="dropdown-options text-sm text-[#000] nunito-sans-light shadow-lg shadow-gray-300">
                  <div v-for="option in sortOptions" :key="option" @click="selectSortOrder(option)" class="dropdown-option text-sm text-[#000] nunito-sans-light w-40">
                    {{ option }}
                  </div>
                </div>
              </div>
            </div>

            <div class="relative">
              <label class="mr-2 text-sm text-[#5C5C5C] nunito-sans-light">Show:</label>
              <div class="dropdown-container w-14" @click="toggleDropdown('itemsPerPage')">
                <div class="dropdown-select text-sm text-[#000] nunito-sans-light">
                  {{ itemsPerPage }}
                </div>
                <div v-if="isItemsPerPageOpen" class="dropdown-options text-sm text-[#000] nunito-sans-light shadow-lg shadow-gray-300">
                  <div v-for="size in [30, 45, 60]" :key="size" @click="setItemsPerPage(size)" class="dropdown-option text-sm text-[#000] nunito-sans-light w-14">
                    {{ size }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
            <!-- Modified ProductCard with click event to trigger redirection -->
            <ProductCard 
              v-for="product in paginatedProducts" 
              :key="product.id" 
              :product="product" 
              @click="redirectToProductDetail(product.id)" 
            />
          </div>

          <!-- Pagination Controls -->
          <div class="pagination flex justify-center items-center mt-6 mb-12">
            <button @click="changePage(1)" :disabled="currentPage === 1" class="pagination-button">
              <img src="@/assets/Vector 18.svg" alt="First Page" class="w-2.5" />
            </button>

            <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1" class="pagination-button">
              <img src="@/assets/Vector 18.svg" alt="First Page" class="w-2.5" />
              <img src="@/assets/Vector 18.svg" alt="First Page" class="w-2.5" />
            </button>

            <span v-if="currentPage > 2" @click="changePage(1)" class="pagination-number cursor-pointer">1</span>
            <span v-if="currentPage > 3">...</span>

            <button v-for="page in visiblePages" :key="page" @click="changePage(page)" :class="[
              'pagination-button',
              page === currentPage ? 'active' : ''
            ]" class="pagination-number">
              {{ page }}
            </button>

            <span v-if="currentPage < totalPages - 2">...</span>
            <span v-if="currentPage < totalPages - 1" @click="changePage(totalPages)" class="pagination-number cursor-pointer">
              {{ totalPages }}
            </span>

            <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages" class="pagination-button">
              <img src="@/assets/Vector 18.svg" alt="Last Page" class="w-2.5 rotate-180" />
              <img src="@/assets/Vector 18.svg" alt="Last Page" class="w-2.5 rotate-180" />
            </button>

            <button @click="changePage(totalPages)" :disabled="currentPage === totalPages" class="pagination-button">
              <img src="@/assets/Vector 18.svg" alt="Last Page" class="w-2.5 rotate-180" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <SiteFooter />
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import FilterBar from '@/components/FilterBar.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import TopBanner from '@/components/TopBanner.vue';
import jsCookie from 'js-cookie';

export default {
  components: {
    ProductCard,
    FilterBar,
    SiteFooter,
    TopBanner,
  },
  data() {
    return {
      topBannerData: {
        title: '',
        description: '',
        longDescription: '',
      },
      products: [
        { id: 1, soldDate: "21/09/2024", image: require('@/assets/1718862141IMG-0347 4.png'), lotNumber: "357830", reserveStatus: "Reserve has been met", title: "Buffalo Trace Bourbon Very Fine Rare", winningBid: "$200.00" },
        { id: 2, soldDate: "23/09/2024", image: require('@/assets/1718862141IMG-0347 4.png'), lotNumber: "357831", reserveStatus: "Reserve has not been met", title: "Knob Creek 9 Year Old Bourbon", winningBid: "$75.00" },
        { id: 3, soldDate: "26/09/2024", image: require('@/assets/1718862141IMG-0347 4.png'), lotNumber: "357832", reserveStatus: "Reserve has been met", title: "Maker's Mark Bourbon", winningBid: "$150.00" },
      ],
      currentPage: 1,
      itemsPerPage: 30,
      totalPages: 1,
      sortOrder: 'Newest First',
      sortOptions: ['Newest First', 'Oldest First', 'Highest Price', 'Lowest Price'],
      visiblePages: [],
      isSortOrderOpen: false,
      isItemsPerPageOpen: false,
      searchQuery: '',
    };
  },
  computed: {
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.products.slice(start, end);
    },
    sortOrderText() {
      return this.sortOrder;
    }
  },
  methods: {
    redirectToProductDetail(productId) {
      this.$router.push({ name: 'PastAuctionDetailPage', params: { id: productId } });
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.updatePagination();
      }
    },
    updatePagination() {
      this.visiblePages = [];
      const totalPages = Math.ceil(this.products.length / this.itemsPerPage);
      this.totalPages = totalPages;

      for (let i = 1; i <= totalPages; i++) {
        this.visiblePages.push(i);
      }
    },
    toggleDropdown(type) {
      if (type === 'sortOrder') {
        this.isSortOrderOpen = !this.isSortOrderOpen;
      } else if (type === 'itemsPerPage') {
        this.isItemsPerPageOpen = !this.isItemsPerPageOpen;
      }
    },
    selectSortOrder(option) {
      this.sortOrder = option;
      this.isSortOrderOpen = false;
    },
    setItemsPerPage(size) {
      this.itemsPerPage = size;
      this.isItemsPerPageOpen = false;
      this.updatePagination();
    }
  },
  mounted() {
    const auctionTitle = jsCookie.get('auction_title');
    const auctionDescription = jsCookie.get('auction_description');

    if (auctionTitle && auctionDescription) {
      this.topBannerData.title = auctionTitle;
      this.topBannerData.description = auctionDescription;
      this.topBannerData.longDescription = auctionDescription;
    }
  },
  created() {
    this.updatePagination();
  }
};
</script>




<style scoped>
.container {
  max-width: 1280px;
}

.dropdown-select::after {
  content: "▼";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  color: black;
  pointer-events: none;
  transition: transform 0.2s ease;
}

.dropdown-select.rotate::after {
  transform: translateY(-50%) rotate(180deg);
}

.dropdown-container {
  display: inline-block;
  position: relative;
}

.dropdown-select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  cursor: pointer;
  padding-right: 30px;
  
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #8a2432;
  z-index: 10;
}

.dropdown-option {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #fde7e9;
}


.pagination-button {
  border-radius: 50%;
  /* Circular shape */
  width: 32px;
  /* Fixed width */
  height: 32px;
  /* Fixed height */
  display: flex;
  /* Use flexbox to center text */
  justify-content: center;
  /* Center text horizontally */
  align-items: center;
  /* Center text vertically */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: background-color 0.3s;
  /* Smooth background change */
  margin: 0px 6px 0px 6px !important;
}

.pagination-button:hover {
  background-color: #f0f0f0;
}

.active {
  background-color: #e0e0e0;
}

.pagination-number {
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 24px;
  margin: 0 0.25rem;
  transition: background-color 0.3s ease;
}

.pagination-number:hover {
  background-color: #f0f0f0;
}

</style>

<template>
  <div class="product-card border overflow-hidden border-[#e399a3] hover:ring-2 hover:border-transparent hover:ring-[#8a2432] hover:ring-offset-0 bg-[#8A24320D]">
    <!-- Lot Number and Favorite Icon -->
    <div class="flex justify-between items-center bg-white px-4 pt-2 text-gray-500 text-[10px]">
      <div>Lot #{{ product.lotNumber }}</div>
      <button class="star-button focus:outline-none" @click="toggleFavorite">
        <img :src="isFavorite ? require('@/assets/Star 1.svg') : require('@/assets/Star 2.svg')" alt="Favorite" class="star-icon" />
      </button>
    </div>

    <!-- Product Image -->
    <img :src="product.image" alt="Product Image" class="w-full object-contain" />

    <!-- Product Details -->
    <div class="px-4 py-6 text-center">
      <h3 class="product-title nunito-sans-light text-black leading-4 text-[14px] mb-2">{{ product.title }}</h3>

      <!-- Reserve Price and Current Bid with Vertical Separator -->
      <div class="flex items-center justify-center text-xs text-gray-600 mt-2">
        <!-- Reserve Price Column -->
        <div class="flex flex-col items-end">
          <p class="text-[#3D3D3D] text-[11px] nunito-sans-light">Reserve Price</p>
          <p class="text-[16px] text-black nunito-sans-light">{{ product.reservePrice }}</p>
        </div>

        <!-- Vertical Separator -->
        <div class="border-l border-[#646464] h-7 mx-4"></div>

        <!-- Current Bid Column -->
        <div class="flex flex-col items-start">
          <p class="text-[#8a2432] text-[11px] nunito-sans-light">Current Bid</p>
          <p class="text-[16px] text-black nunito-sans-light custom-font-weight ">{{ product.winningBid }}</p>
        </div>
      </div>

      <!-- Buttons -->
      <div class="flex justify-between gap-4 mt-4">
        <button class="flex-1 bg-[#f9f3f4] border border-[#8a2432] text-[12px] nunito-sans-custom text-[#8a2432] py-2 hover:border-[black] hover:bg-black hover:text-white">
          View Lot
        </button>
        <button class="flex-1 py-2 bg-[#8a2432] border text-[12px] nunito-sans-custom text-white hover:bg-white hover:border-[#8a2432] hover:text-[#8a2432]">
          Quick Bid
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFavorite: false, // Default state of the star
    };
  },
  methods: {
    toggleFavorite() {
      this.isFavorite = !this.isFavorite; // Toggle the state of the star on click
    },
  },
};
</script>

<style scoped>
.product-title {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis */
  font-weight: 500;
}

.star-button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.star-icon {
  width: 22px;
  height: 22px;
}

.custom-font-weight {
  font-weight: 700;
}
</style>
